<template>
    <div>
        <component
            :is="getFormieComponent('formField')"
            :field="field"
        >
            <component
                :is="getFormieComponent('input')"
                v-model="value"
                type="text"
                v-bind="getField(field).getInputBindings()"
            />
        </component>
    </div>
</template>

<script setup>
defineProps({
    field: {
        type: Object,
        required: true,
    }
});

const value = defineModel({ type: [String, null], required: true });
</script>
